<template>
  <!-- 提醒事项修改页面 -->
  <moe-page title="提醒事项修改">
    <moe-form ref="matterForm" :model="matter" :rules="matterRules">
      <el-form-item label="提醒事项名称" prop="name">
        <el-input
          v-model.trim="matter.name"
          placeholder="请输入提醒事项品牌"
          maxlength="50"
          clearable />
      </el-form-item>
      <el-form-item label="提醒说明" prop="remarks">
        <el-input
          v-model.trim="matter.remarks"
          placeholder="请输入提醒说明"
          maxlength="50"
          clearable />
      </el-form-item>
      <el-form-item label="提醒主图" prop="img">
        <moe-upload-file
          v-model="matter.img"
          ossKey="ICON"
          :default-file-list="imageList"
          upload-text="上传图片"
          @change="$refs.matterForm.validateField('img')" />
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="matterLoad"
          @click="matterInfoSubmit">
          {{ matterLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'PetMatterAmend',
  data() {
    //提醒事项信息
    const matter = {
      name: '', //事项名称
      remarks: '', //事项说明
      img: '', //事项主图
    }

    //提醒事项数据校验
    const verify = this.$moe_verify.verifyForm
    const matterRules = {
      name: verify.isExtent(['请输入事项名称', '长度为 1 ~ 30'], '1~30'),
      remarks: verify.isExtent(['请输入事项名称', '长度为 1 ~ 100'], '1~100'),
      img: verify.isEmpty('请上传事项主图'),
    }

    return {
      matter, //提醒事项信息
      matterRules, //提醒事项数据校验
      imageList: [], //图片列表
      matterLoad: false, //提交状态
    }
  },
  created() {
    //获取宠物事项详情
    this.getMatterDetail()
  },
  methods: {
    /**
     * 获取宠物事项详情
     **/
    getMatterDetail() {
      const id = this.$route.query.id
      // 调用获取宠物事项详情API
      this.$moe_api.PET_API.petMatterDetail({ id }).then((data) => {
        if (data.code == 200) {
          let m = data.result

          // 回显宠物分类
          this.matter = {
            id: m.id, //事项ID
            name: m.name, //事项名称
            remarks: m.remarks, //事项说明
            img: this.$moe_yunHelper.formatterImageUrl(m.img), //事项主图
          }

          // 商品视频文件列表
          if (m.img) {
            this.imageList = [
              {
                url: m.img,
                tempFilePath: this.$moe_yunHelper.getTempFilePath(m.img)
              },
            ]
          }
        } else {
          this.$moe_msg.error(data.message)
        }
      })
    },

    /* 提交提醒事项信息 */
    matterInfoSubmit() {
      // 表单校验
      this.$refs['matterForm'].validate(() => {
        this.matterLoad = true
        this.$moe_api.PET_API.petMatterAmend(this.matter)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('修改成功')
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message)
            }
          })
          .finally(() => {
            this.matterLoad = false
          })
      })
    },
  },
}
</script>
